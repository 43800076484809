import { getFileExt } from './live_graphics';

const VIDEO_EXTENSIONS = ['webm', 'mp4'];
const isVideo = (src) => {
  const ext = getFileExt(src);

  return VIDEO_EXTENSIONS.includes(ext);
};

export default class Popup {
  constructor(data) {
    this.text = data.text;
    this.image = data.image;
    this.color = data.text_color;
    this.id = data.id;

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.renderImg = this.renderImg.bind(this);
    this.renderVideo = this.renderVideo.bind(this);
  }

  renderImg() {
    const { body } = document;
    body.insertAdjacentHTML(
      'beforeend',
      `
      <div class="on-conversion-wrapper" id="on-conversion-wrapper-${this.id}">
        <img class="on-conversion-image fade-in" src="${this.image}" />
        <div class="on-conversion-text fade-in" style="color: ${this.color}">
          ${this.text}
        </div>
      </div>
    `,
    );
  }

  renderVideo() {
    const { body } = document;
    const videoWrapper = document.createElement('div');
    videoWrapper.className = 'fullscreen-video-wrapper';
    videoWrapper.id = `on-conversion-wrapper-${this.id}`;

    const video = document.createElement('video');
    video.src = this.image;
    video.className = 'fullscreen-video';
    videoWrapper.appendChild(video);

    const text = document.createElement('div');
    text.className = 'on-conversion-text fade-in';
    text.style.color = this.color;
    text.textContent = this.text;
    videoWrapper.appendChild(text);

    body.appendChild(videoWrapper);
    return video;
  }

  show() {
    if (isVideo(this.image)) {
      const video = this.renderVideo();
      video.play();
    } else {
      this.renderImg();
    }
  }

  hide() {
    const header = document.querySelector(
      `#on-conversion-wrapper-${this.id} ${
        isVideo(this.image) ? 'video' : 'img'
      }`,
    );
    header.classList.remove('fade-in');
    header.classList.add('fade-out');

    const message = document.querySelector(
      `#on-conversion-wrapper-${this.id} div`,
    );
    message.classList.remove('fade-in');
    message.classList.add('fade-out');

    setTimeout(() => {
      const wrapper = document.getElementById(
        `on-conversion-wrapper-${this.id}`,
      );
      wrapper.parentNode.removeChild(wrapper);
    }, 2000);
  }

  run() {
    this.show();
    setTimeout(this.hide, 10000);
  }
}
