import { broadcaster } from './url';

/* eslint-disable class-methods-use-this,no-prototype-builtins */
export default class WidgetManager {
  /*
   * Handles adding/removing widgets to the browsersource. The overlay is
   * displayed in an iframe, a la Twitch widgets
   */
  constructor(connection) {
    this.widgets = {};
    this.connection = connection;

    this.update = this.update.bind(this);
  }

  update(widgets) {
    /*
     * Each widget should be in the form of:
     *    {
     *      'src': OVERLAY_URL,
     *      'id': COMPONENT_ID,
     *    }
     *
     * This function should build the iframe for each one
     */
    const newWidgets = widgets.reduce((reduced, widget) => ({
      ...reduced,
      [`${widget.id}`]: widget.src,
    }), {});

    widgets.forEach((widget) => {
      if (!this.widgets.hasOwnProperty(widget.id) || this.widgets[widget.id] !== widget.src) {
        this.addWidget(widget);
      }
    });

    const iframes = document.querySelectorAll('.widget-iframe');
    iframes.forEach((iframe) => {
      const id = iframe.id.split('widget-')[1];
      if (!newWidgets.hasOwnProperty(id)) {
        iframe.parentNode.removeChild(iframe);
      }
    });

    this.widgets = newWidgets;
  }

  addWidget(widget) {
    const iframeId = `widget-${widget.id}`;
    let iframe = document.getElementById(iframeId);

    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.id = iframeId;
      iframe.frameborder = '0';
      iframe.marginheight = '0';
      iframe.marginwidth = '0';
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.className = 'widget-iframe';
      iframe.src = widget.src;
      document.body.appendChild(iframe);

      iframe.addEventListener('load', () => {
        iframe.contentWindow.postMessage(
          { widgetId: widget.id, broadcaster }, '*',
        );
      });
    } else if (iframe.src !== widget.src) {
      iframe.src = widget.src;
    }
  }

  removeWidget(widgetId) {
    const iframeId = `widget-${widgetId}`;
    const iframe = document.getElementById(iframeId);
    iframe.parentNode.removeChild(iframe);
  }
}
