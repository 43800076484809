import emptyImg from '../img/empty.png';

export const DEFAULT_ROTATION_POSITION = { xPos: 0, yPos: 0, scale: 1.0 };
export const DEFAULT_POSITION_KEY = '_';

export const getFileExt = (filename) => {
  /*
   * Utility function for extracting and normalizing a filename extension.
   */
  const tokens = filename.split('.');
  return tokens[tokens.length - 1].toLowerCase();
};

export const differentPositions = (posA, posB) =>
  posA.xPos !== posB.xPos ||
  posA.yPos !== posB.yPos ||
  posA.scale !== posB.scale;

export const makeImage = (src, cls, xPos, yPos, scale, noLoop) => {
  /*
   * Converts from URL (src) to HTML based on the file type,
   * to then be added to the image rotation.
   */
  const extension = getFileExt(src);

  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif': {
      const img = document.createElement('img');
      img.src = src;
      img.style = `transform: translate(${xPos || 0}px, ${yPos || 0}px) scale(${
        scale || 1.0
      });`;
      img.className = `browsersource-image ${cls}`;
      return img;
    }
    case 'mp4':
    case 'webm': {
      const tempWrapper = document.createElement('div');
      const videoString = `<video 
      src="${src}" 
      class="${cls}" 
      autoplay 
      ${noLoop ? '' : 'loop '}
      muted 
      style="transform: translate(${xPos || 0}px, ${yPos || 0}px) scale(${
        scale || 1.0
      });">
      </video>`;
      tempWrapper.innerHTML = videoString;
      return tempWrapper.firstChild;
    }
    default:
      return null;
  }
};

export class ImageRotation {
  /*
   * This class handles all the browsersource live graphics rotations,
   * including tracking which image is currently live, what the total set
   * of current images is, and scheduling the rotation to the next image
   *
   * USAGE:
   *    rotation = new ImageRotation()  // creates a rotation. Only needs to be done once
   *    rotation.update(IMAGES)         // pass an array of images from the server to
   *                                       update the images in rotation
   */
  constructor(name, connection, options = {}) {
    this.name = name;
    this.wrapperId = `rotation-${this.name}`;
    this.images = [];
    this.currentImageIndex = 0;
    this.updateTimer = null;
    this.lastRotationTime = null;
    this.connection = connection;
    this.isStreaming = options.isStreaming || false;
    this.currentScene = options.currentScene || '';
    this.positions = {};

    const wrapper = document.getElementById('browsersource-image');
    wrapper.innerHTML += `<div id="${this.wrapperId}" />`;

    this.render = this.render.bind(this);
    this.recordImpression = this.recordImpression.bind(this);
    this.onStreamStart = this.onStreamStart.bind(this);
    this.onStreamStop = this.onStreamStop.bind(this);
    this.onSceneChange = this.onSceneChange.bind(this);
    this.getDefaultPosition = this.getDefaultPosition.bind(this);
    const searchParams = new URLSearchParams(window.location.search);
    const forceImpressions = searchParams.get('force-impressions');
    this.forceImpressions =
      (forceImpressions && forceImpressions.toLowerCase() === 'true') ||
      FORCE_IMPRESSION_SEND;
  }

  clearTimer() {
    /*
     * clear the previous timer, so we don't have multiple simultaneous rotations
     */
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
      this.updateTimer = null;
    }
  }

  getCurrentImage() {
    return this.images[this.currentImageIndex];
  }

  getPreviousImage() {
    const index = this.currentImageIndex;
    return this.images[index !== 0 ? index - 1 : this.images.length - 1];
  }

  static getScreenArea() {
    /*
     * Returns the percentage of the canvas covered by the Live Graphic,
     * currently to two decimals places precision
     *
     */
    const screenArea = window.innerWidth * window.innerHeight;
    let imageElement = document.querySelector('.prev');

    if (!imageElement) {
      imageElement = document.querySelector('.current');
    }

    if (!imageElement) {
      return 0;
    }

    const boundingBox = imageElement.getBoundingClientRect();
    const imageArea = boundingBox.width * boundingBox.height;

    return Math.round((imageArea / screenArea) * 10000) / 100;
  }

  recordImpression(image) {
    if (this.isStreaming || this.forceImpressions) {
      const previous = this.lastRotationTime;
      const now = Date.now();

      if (previous && image.version_id) {
        const impressionData = {
          start: previous,
          duration: (now - previous) / 1000,
          coverage: this.constructor.getScreenArea(image, 'prev'),
          version_id: image.version_id,
        };

        this.connection.send({
          action: 'record-lg-impressions',
          value: impressionData,
        });
      }

      this.lastRotationTime = now;
    }
  }

  getDefaultPosition() {
    return this.positions[DEFAULT_POSITION_KEY] || DEFAULT_ROTATION_POSITION;
  }

  render(forceUpdate) {
    if (this.images.length > 1 || (this.images.length > 0 && forceUpdate)) {
      const currentImage = this.getCurrentImage();
      const prevImage = this.getPreviousImage();
      const wrapper = document.getElementById(this.wrapperId);

      const defaultPosition = this.getDefaultPosition();
      const rotationPosition =
        this.positions[this.currentScene] || defaultPosition;

      const prev = wrapper.getElementsByClassName('prev');
      for (let i = 0; i < prev.length; i += 1) {
        prev[i].parentNode.removeChild(prev[i]);
      }

      const current = wrapper.getElementsByClassName('current');
      for (let i = 0; i < current.length; i += 1) {
        current[i].classList.add('prev');
        current[i].classList.remove('current');
      }

      const newImage = makeImage(
        currentImage.image_url,
        'current',
        rotationPosition.xPos,
        rotationPosition.yPos,
        rotationPosition.scale,
        currentImage.no_loop,
      );
      if (newImage) {
        wrapper.appendChild(newImage);
      }

      this.currentImageIndex =
        (this.currentImageIndex + 1) % this.images.length;
      this.clearTimer();
      this.updateTimer = setTimeout(
        () => this.render(false),
        currentImage.duration,
      );

      this.recordImpression(prevImage);
    } else if (Object.keys(this.images).length === 1) {
      // For the purposes of tracking impressions, we still want to report a "rotation",
      // even if there's only one image, so we get viewership data at the image's set
      // rotation rate
      const image = this.images[0];
      this.recordImpression(image);
      this.updateTimers = setTimeout(() => this.render(false), image.duration);
    } else if (Object.keys(this.images).length === 0) {
      const wrapper = document.getElementById(this.wrapperId);
      wrapper.innerHTML = '';
    }
  }

  update(images, positions, pauseTime) {
    /*
     * Handles new image data coming from the server, including:
     *    - Adding the new images to their appropriate loops
     *    - reseting the loop to start with the updated data
     */
    const parsedImages = [];

    images.forEach((image) => {
      parsedImages.push(image);

      if (image.delay && image.delay > 0) {
        parsedImages.push({
          image_url: emptyImg,
          duration: image.delay,
        });
      }
    });

    if (parsedImages.length > 0 && pauseTime > 0) {
      parsedImages.push({
        image_url: emptyImg,
        duration: pauseTime,
      });
    }

    this.images = parsedImages;
    this.currentImageIndex = 0;
    this.positions = positions;

    this.clearTimer();
    setTimeout(() => this.render(true), 0);
  }

  onStreamStart() {
    this.isStreaming = true;
  }

  onStreamStop() {
    this.isStreaming = false;

    // Send the final impression set (since it will be mid-rotation)
    const prevImage = this.getPreviousImage();
    if (prevImage) {
      this.recordImpression(prevImage);
    }

    // reset previous rotations in case browsersource is not restarted
    // before next stream
    this.lastRotationTime = null;
  }

  onSceneChange(newSceneName) {
    /*
     * If the new position and current position are different, update the live positions of
     * the current image.  Then, set the current scene name
     */
    const defaultPosition = this.getDefaultPosition();
    const currentPosition =
      this.positions[this.currentScene] || defaultPosition;
    const newPosition = this.positions[newSceneName] || defaultPosition;

    if (differentPositions(currentPosition, newPosition)) {
      const wrapper = document.getElementById(this.wrapperId);
      const currentImage = wrapper.getElementsByClassName('current')[0];

      if (currentImage) {
        currentImage.style.transform = `translate(${newPosition.xPos || 0}px, ${
          newPosition.yPos || 0
        }px) scale(${newPosition.scale || 1.0})`;
      }
    }

    this.currentScene = newSceneName;
  }
}

export default class RotationManager {
  constructor(connection) {
    this.rotations = {};
    this.connection = connection;
    this.currentScene = '';
    this.isStreaming = false;

    this.onStreamStart = this.onStreamStart.bind(this);
    this.onStreamStop = this.onStreamStop.bind(this);
    this.onSceneChange = this.onSceneChange.bind(this);
  }

  update(newRotations) {
    // Add new rotations if they don't already exist, and then update
    Object.keys(newRotations).forEach((rotationName) => {
      const rotation = newRotations[rotationName];

      if (!(rotationName in this.rotations)) {
        this.rotations[rotationName] = new ImageRotation(
          rotationName,
          this.connection,
          { isStreaming: this.isStreaming, currentScene: this.currentScene },
        );
      }

      this.rotations[rotationName].update(
        rotation.images,
        rotation.positions,
        rotation.loopPause,
      );
    });

    // If we have an active rotation that was NOT included in the new rotations,
    // that means the rotation was removed and we should remove it from the live
    // browsersource
    Object.keys(this.rotations).forEach((rotationName) => {
      const rotation = this.rotations[rotationName];
      if (!(rotationName in newRotations) && rotation.images.length > 0) {
        rotation.update([]);
      }
    });
  }

  onStreamStart() {
    this.isStreaming = true;

    Object.values(this.rotations).forEach((rotation) => {
      rotation.onStreamStart();
    });
  }

  onStreamStop() {
    this.isStreaming = false;

    Object.values(this.rotations).forEach((rotation) => {
      rotation.onStreamStop();
    });
  }

  onSceneChange(newScene) {
    this.currentScene = newScene;

    Object.values(this.rotations).forEach((rotation) => {
      rotation.onSceneChange(newScene);
    });
  }
}
