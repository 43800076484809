import { NO_MATCH, BROWSERSOURCE_NOT_SETUP } from './error_codes';
import { legacyUrl } from './url';


export default class Connection {
  constructor(url, onMessage) {
    // target url to connect to
    this.url = url;
    // function that handles incoming messages
    this.onMessage = onMessage;
    this.connected = false;
    this.autoreconnect = true;

    this.onConnect = this.onConnect.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  connect() {
    // connect to server
    this.ws = new WebSocket(this.url);
    this.ws.onopen = this.onConnect;
    this.ws.onmessage = this.onMessage;
    this.ws.onclose = this.onClose;
  }

  onConnect() {
    this.connected = true;
  }

  onClose(e) {
    this.connected = false;
    // clear previous websocket
    this.ws = null;

    // take appropriate action based on server-returned error code
    switch (e.code) {
      case NO_MATCH:
        this.autoreconnect = false;
        break;
      case BROWSERSOURCE_NOT_SETUP:
        window.location.replace(legacyUrl);
        break;
      default:
        if (LOGGING_ENABLED) {
          // eslint-disable-next-line no-console
          console.log(`Received unhandled error code: ${e.code}`);
        }
    }
  }

  reconnect() {
    if (!this.connected && this.autoreconnect && !this.ws) {
      if (LOGGING_ENABLED) {
        // eslint-disable-next-line no-console
        console.log('Attempting reconnect...');
      }
      this.connect();
    }
  }

  start() {
    // create initial connection
    this.connect();
    // start reconnect checking loop
    this.reconnector = setInterval(
      this.reconnect.bind(this),
      +CONNECTION_TIMEOUT * 1000,
    );
  }

  send(data) {
    if (LOGGING_ENABLED) {
      // eslint-disable-next-line no-console
      console.log(`sending: ${JSON.stringify(data)}`);
    }
    this.ws.send(JSON.stringify(data));
  }
}
