// Checks the validity of various possible URL patterns
const previewUrlRegex = /^https?:\/\/([\w-:.]+)\/preview\/([\w]+)\/([\w]{4,24})\/?(\?|$)([^/&?]*?=[^/&?])*/;
const channelUrlRegex = /^https?:\/\/([\w-:.]+)\/([\w]{3,24})\/?(\?|$)([^/&?]*?=[^/&?])*/;
export const isPreviewUrl = url => previewUrlRegex.test(url);
export const isChannelUrl = url => channelUrlRegex.test(url);
export const isValidUrl = url => isPreviewUrl(url) || isChannelUrl(url);

// Parses the passed URL and returns a browsersource target URL
export const targetFromPreviewUrl = (url, isLegacyRedirect) => {
  const [, , campaignKey, broadcaster] = previewUrlRegex.exec(url);
  return isLegacyRedirect
    ? `${LEGACY_URL}preview/${campaignKey}/${broadcaster}`
    : `${PROTOCOL}://${HOST}/browsersource-preview/${campaignKey}/${broadcaster}/`;
};

export const targetFromChannelUrl = (url, isLegacyRedirect) => {
  const [, , broadcaster] = channelUrlRegex.exec(url);
  return isLegacyRedirect
    ? `${LEGACY_URL}${broadcaster}`
    : `${PROTOCOL}://${HOST}/browsersource-standalone/${broadcaster}/`;
};

export const getFromValidUrl = (url, isLegacyRedirect) => isPreviewUrl(url)
  ? targetFromPreviewUrl(url, isLegacyRedirect) : targetFromChannelUrl(url, isLegacyRedirect);

export const getTargetUrl = (url, isLegacyRedirect) => isValidUrl(url)
  ? getFromValidUrl(url, isLegacyRedirect) : undefined;

export const targetUrl = getTargetUrl(window.location.href);

export const legacyUrl = getTargetUrl(window.location.href, true);

export const getBroadcasterFromUrl = (url) => {
  if (isChannelUrl(url)) {
    const [, , broadcaster] = channelUrlRegex.exec(url);
    return broadcaster;
  }

  if (isPreviewUrl(url)) {
    const [, , , broadcaster] = previewUrlRegex.exec(url);
    return broadcaster;
  }

  return '';
};

export const broadcaster = getBroadcasterFromUrl(window.location.href);
